<template>
  <div class="forget-contaner">
    <Header></Header>
    <!-- 登录区域 start -->
    <el-row class="login-box" type="flex" align="middle">
      <el-row class="contaner">
        <el-row class="login" type="flex" align="middle" justify="center">
          <img class="img" src="@/assets/images/login/logo.png" alt="" />
        </el-row>
        <!-- 表单区域 s -->
        <el-row class="form-box">
          <el-row class="form">
            <el-row
              class="title-box"
              type="flex"
              justify="center"
              align="middle"
            >
              <p>重置密码</p>
              <div><img src="@/assets/images/login/hengxian.png" alt="" /></div>
            </el-row>
            <!-- 表单 s -->
            <el-form
              :model="ruleForm"
              :status-icon="false"
              :rules="rules"
              ref="ruleForm"
              class="demo-ruleForm"
            >
              <el-form-item prop="phone">
                <el-input
                  type="phone"
                  v-model="ruleForm.phone"
                  placeholder="手机号/账号"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item prop="code">
                <el-input
                  type="number"
                  v-model="ruleForm.code"
                  placeholder="验证码"
                  autocomplete="off"
                ></el-input>
                <span class="get-code" @click="getCodeHandle">获取验证码</span>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  :type="type"
                  v-model="ruleForm.password"
                  placeholder="新密码：6-16位字符，包含字母和数字"
                  autocomplete="off"
                ></el-input>
                <img
                  v-if="type == 'text'"
                  class="eye-img"
                  src="@/assets/images/home/eye.png"
                  alt=""
                  @click="changeEye"
                />
                <img
                  v-if="type == 'password'"
                  class="eye-img"
                  src="@/assets/images/home/close-eye.png"
                  alt=""
                  @click="changeEye"
                />
              </el-form-item>
              <el-form-item>
                <router-link :to="{ name: 'login' }">
                  <el-button @click.prevent="submitForm('ruleForm')"
                    >确定</el-button
                  >
                </router-link>
              </el-form-item>
              <p class="button-text">
                已有账号？
                <router-link :to="{ name: 'login' }">
                  <span>马上登录</span>
                </router-link>
              </p>
            </el-form>
            <!-- 表单 e -->
          </el-row>
        </el-row>
        <!-- 表单区域 end -->
      </el-row>
    </el-row>
    <!-- 登录区域 end -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/home/Header.vue";
import Footer from "@/components/home/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    var validatePone = (rule, value, callback) => {
      console.log(/^1[3-9]\d{9}$/.test(this.ruleForm.phone));
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        if (!/^1[3-9]\d{9}$/.test(this.ruleForm.phone)) {
          callback(new Error("手机号格式不正确"));
        }
      }
      callback();
    };
    var validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      }
      callback();
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (
          !/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/.test(
            this.ruleForm.password
          )
        ) {
          callback(new Error("密码包含数字和字母，且在6-18位之间"));
        }
      }
      callback();
    };
    return {
      type: "password",
      ruleForm: {
        phone: "",
        code: "",
        password: "",
      },
      rules: {
        phone: [{ validator: validatePone, trigger: "blur" }],
        code: [{ validator: validateCode, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
      },
    };
  },

  methods: {
    // 小眼睛
    changeEye() {
      if (this.type == "text") {
        this.type = "password";
      } else if (this.type == "password") {
        this.type = "text";
      }
    },
    // 提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let changePwdParams = {
            captcha: String(this.ruleForm.code),
            mobile: String(this.ruleForm.phone),
            password: this.ruleForm.password,
          };
          // this.$API
          //   .forgetPwd(changePwdParams)
          //   .then((res) => {
          //     if (res.code == 1) {
          //       this.$message({
          //         message: "修改成功",
          //         type: "success",
          //       });
          //       setTimeout(() => {
          //         this.$router.push({ name: "login" });
          //       }, 500);
          //     } else {
          //       this.$message.info(res.msg);
          //     }
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //   });

          // 验证成功，可登录
          // 跳转至首页
          // this.$router.push("/home");
          // 校验验证码
          let checkCodeParams = {
            captcha: this.ruleForm.code,
            mobile: this.ruleForm.phone,
            event: "foget",
            // proportion: this.ruleForm.password,
          };
          this.$API
            .checkCode(checkCodeParams)
            .then((res) => {
              if (res.code == 1) {
                console.log("验证码校验成功");
                // 验证码校验成功后去更改密码
                let changePwdParams = {
                  captcha: this.ruleForm.code,
                  mobile: this.ruleForm.phone,
                  password: this.ruleForm.password,
                };
                this.$API
                  .forgetPwd(changePwdParams)
                  .then((res) => {
                    if (res.code == 1) {
                      this.$message({
                        message: "修改成功",
                        type: "success",
                      });
                      setTimeout(() => {
                        this.$router.push({ name: "login" });
                      }, 500);
                    } else {
                      this.$message.info(res.msg);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              } else {
                this.$message.info(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          return false;
        }
      });
    },
    // 获取验证码
    getCodeHandle() {
      if(!this.ruleForm.phone){
        this.$message.info("请先输入手机号");
        return
      }
      let paramData = {
        event: "forget",
        userName: this.ruleForm.phone,
      };
      this.$API
        .getCode(paramData)
        .then((res) => {
          if (res.code == 1) {
            // 369258
            // this.ruleForm.code = 369258;
            this.$message.info(res.msg);
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss">
.forget-contaner {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  min-width: 1200px;
  .login-box {
    width: 100%;
    height: 798px;
    background-image: url("../assets/images/login/bg.png");
    background-size: 100% 100%;
    object-fit: cover;
  }
  .contaner {
  }
  .login {
    float: left;
    width: 580px;
    height: 620px;
    background-image: url("../assets/images/login/uservice.png");
    background-size: 1207px 620px;
    object-fit: cover;
    .img {
      width: 253.78px;
      height: 200.56px;
      object-fit: cover;
    }
  }
  // 表单区域
  .form-box {
    float: left;
    width: 620px;
    height: 620px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 100px 123px 43px 123px;
    .form {
      .title-box {
        flex-direction: column;
        margin: 0 auto;
        p {
          font-size: 32px;
          font-weight: 500;
          color: #333333;
          line-height: 49px;
          margin: 0 auto;
        }
        img {
          width: 32px;
          height: 4px;
          margin: 0 auto;
        }
      }
    }
  }
  .el-form {
    margin-top: 60px !important;
    .eye-img {
      width: 18px;
      height: 18px;
      position: relative;
      left: 343px;
      top: -39px;
    }
  }
  .el-form-item {
    width: 374px;
    height: 48px;
    .el-input {
      width: 374px;
      height: 48px;
    }
  }
  .el-input__inner {
    width: 374px;
    height: 48px !important;
  }
  .forget-pwd {
    cursor: pointer;
    text-align: right;
    font-size: 16px;
    color: #666666;
    line-height: 17px;
  }
  .el-button {
    margin-top: 88px;
    outline: none;
    width: 380px;
    height: 48px;
    color: #fff;
    background: linear-gradient(360deg, #ed1b23 0%, #f15f64 100%);
    box-shadow: 0px 2px 25px 1px rgba(237, 27, 35, 0.2);
    border-radius: 4px 4px 4px 4px;
    cursor: pointer;
  }
  .el-input__inner:hover {
    border-color: #c0c4cc;
  }
  .el-button:focus,
  .el-button:hover {
    border-color: transparent;
  }
  .button-text {
    text-align: center;
    margin-top: 94px;
    color: #666666;
    font-size: 16px;
    span {
      color: #1577fc;
      cursor: pointer;
    }
  }
  .get-code {
    position: absolute;
    cursor: pointer;
    top: 5px;
    right: 13px;
    font-size: 15px;
    color: #ff4656;
  }
}
</style>
